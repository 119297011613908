import Auth from "@alphatekas/pwr_core/dist/auth";
import CMD from "@alphatekas/pwr_core/dist/cmd";
import Forceplate from "@alphatekas/pwr_core/dist/forceplate";
import Leveller from "@alphatekas/pwr_core/dist/leveller";
import GLI from "@alphatekas/pwr_core/dist/ml/gli";
import Squatter, { Squat } from "@alphatekas/pwr_core/dist/squatter";
import Storage from "@alphatekas/pwr_core/dist/storage";
import { createClient } from "@supabase/supabase-js";
import { TDigest } from "tdigest";

console.log("__INIT__");

const hz = 434.0277777777777;

const cmd = new CMD();

const forceplate = new Forceplate({
    hz,
    active_s: 0.5,
    active_w: 10,
    auto_tare: true,
    deps: { cmd },
});

forceplate.on("active", active => console.log("ACTIVE: " + active));

const leveller = new Leveller({
    seconds: 2,
    variance: 1,
    w_cutoff: 5,
    deps: { forceplate },
});

let model: GLI = null;

let ai_perf_fetch: number = undefined;
let ai_perf_init: number = undefined;
let ai_perf_warmup: number = undefined;
const td = new TDigest(0.1);

const start_fetch = performance.now();
fetch("https://cdn.alphatek.no/ai/onnx/squat/model.params.bin")
    .then(response => {
        ai_perf_fetch = performance.now() - start_fetch;
        return response.arrayBuffer();
    })
    .then(buffer => {
        const start_init = performance.now();
        model = new GLI(
            new Float32Array(buffer),
            [[128, 500], [500, 500], [500, 500], [500, 500], [500, 2]],
        );
        ai_perf_init = performance.now() - start_init;
    })
    .then(() => {
        const start_warmup = performance.now();
        model.run(new Float32Array(128));
        ai_perf_warmup = performance.now() - start_warmup;
    });

const squatter = new Squatter({
    w_cutoff: 3,
    v_cutoff: 0.1,
    t_min: 0.75,
    predict: (input, callback) => {
        if (model) {
            const start = performance.now();
            const output = model.run(input);
            td.push(performance.now() - start);
            storage.update(meta => {
                meta.ai_perf.fetch = ai_perf_fetch;
                meta.ai_perf.init = ai_perf_init;
                meta.ai_perf.warmup = ai_perf_warmup;
                meta.ai_perf.min = td.percentile(0);
                meta.ai_perf.max = td.percentile(1);
                meta.ai_perf["50th"] = td.percentile(0.5);
                meta.ai_perf["95th"] = td.percentile(0.95);
                meta.ai_perf["99th"] = td.percentile(0.99);
            });
            callback(+(output[1] > output[0]));
        }
    },
    deps: {
        forceplate,
        leveller,
    },
});

const URL = "https://pqeiqiyrlenkcddedcpm.supabase.co";
const KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InBxZWlxaXlybGVua2NkZGVkY3BtIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDExMDg2NDAsImV4cCI6MjAxNjY4NDY0MH0.ZE1N_NXXb-5nR0JFSzAgbZKjLT6dttCNWNq99bHKGrs";

const db = createClient(URL, KEY, {
    auth: {
        autoRefreshToken: false, // Prevent invalidating remote token
        storageKey: "alphabase_session", // Use custom storage key
        persistSession: false, // Do not persist the session
    },
});

const auth = new Auth({
    deps: {
        cmd,
        forceplate,
        supabase: db,
    },
});

type AIPerf = {
    fetch: number;
    init: number;
    warmup: number;
    min: number;
    max: number;
    "50th": number;
    "95th": number;
    "99th": number;
};

const storage = new Storage({
    kind: "squat_set",
    keep: {
        min: forceplate.hz, // 1 second
        max: forceplate.hz * 60 * 5, // 5 minutes
    },
    deps: {
        forceplate,
        auth,
    },
    init: () => ({
        level: undefined as number,
        reps: [] as Squat[],
        ai_perf: {} as AIPerf,
        stop_percentage: +localStorage.getItem("squat_stop_percentage") || undefined,
        results_timeout: +localStorage.getItem("squat_results_timeout") || undefined,
    }),
});


leveller.on("level", level => {
    if (level) {
        storage.update(meta => {
            meta.level = level;
        });
    }
    else {
        td.reset();
    }
});

squatter.on("squat", squat => {
    storage.update(meta => {
        meta.reps.push(squat);
    });
});

export {
    auth,
    db,
    cmd,
    forceplate,
    leveller,
    squatter,
    storage,
};
