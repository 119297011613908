import React, { useEffect, useState } from 'react';
import TopBar from './layout/TopBar';
import Menu from './screens/Menu';
import WeighIn from './screens/WeighIn';
import JustLift from './screens/JustLift';
import Connect from "./screens/Connect";
import Background, { Props as BgProps } from "./layout/Background"
import styles from "./App.less";
import ScreenContext, { Screen } from "../helpers/ScreenContext";
import match from "@korkje/match";
import * as AT from "../init";

type Props = {};

function App(props: Props) {
    const [screen, setScreen] = useState<Screen>(Screen.Connect);

    const [bgProps, setBgProps] = useState<BgProps>({
        kind: "regular"
    });

    const trafficLight = (color: string | undefined, blink?: boolean) => {

        if (color === undefined) {
            setBgProps({ kind: "regular" });
        }
        else {
            setBgProps({
                kind: "traffic-light",
                blink: blink,
                color
            });
        }
    };

    const [link, setLink] = useState<string>();

    const onLink = (link: string) => setLink(link);

    useEffect(() => {
        gtag("event", "screen_load", {
            screen: "squat",
        });
        AT.cmd.on("QR", onLink);
        return () => AT.cmd.off("QR", onLink);
    }, []);

    return (
        <ScreenContext.Provider value={[screen, setScreen]}>
            <div className={styles.AppWrapper}>
                <Background {...bgProps} />
                <div className={styles.App}>
                    <TopBar />
                    <main>
                        {match(screen)
                            .on(Screen.Connect, () =>
                                <Connect />)
                            .on(Screen.Menu, () =>
                                <Menu />)
                            .on(Screen.WeighIn, () =>
                                <WeighIn link={link} />)
                            .on(Screen.JustLift, () =>
                                <JustLift trafficLight={trafficLight} />)
                            .result()}
                    </main>
                </div>
            </div>
        </ScreenContext.Provider>
    );
}

export default App;

export {
    App,
    Props,
};
